<template lang="pug">
v-radio-group(hide-details).mt-0
  span
    v-radio(
      v-if="recomendedPayment.label"
      :value="recomendedPayment.value"
      :label="`${recomendedPayment.label} (${recomendedPayment.value}€)`"
      @change="preferedPaymentClicked" 
    )
    v-btn(text color="primary" @click="toggle" v-if="!showMore") show all
    template(v-if="showMore")
      div(
        v-for="item in notRecomendedPayments" :value="item.value"  
        :key="item.value"
      )
        v-radio( data-test="payment-item"
          @change="$emit('change', item)" 
          :label="`${item.label} (${item.value}€)`"
        )
        v-textarea(
          v-if="item.active"
          label="Notes"
          outlined
          placeholder="Indicate why the candidate needs this payment"
          v-model="item.notes"
          :error="formErrors.hasError('notes')"
          :error-messages="formErrors.fields.notes"
        ).payment-notes
      slot
      v-btn(text color="primary" @click="toggle") hide all
</template>

<script>
export default {
  props: {
    payments: Array,
    recomendedPayment: Object,
    formErrors: Object
  },

  data: () => ({
    showMore: false
  }),

  computed: {
    
    notRecomendedPayments() {
      if(!this.recomendedPayment) return this.payments
      return this.payments.filter(payment => payment.label !== this.recomendedPayment.label)
    }
  },

  methods: {
    toggle() {
      this.showMore = !this.showMore
    },

    preferedPaymentClicked(val) {
      this.$emit('change', this.recomendedPayment)
    }
  }
}
</script>

<style lang="scss">
.payment-notes {
  padding-top: 5px !important;
  .v-input__control {
    width: 100%;
  }
}
</style>